<template>
    
    <!-- Structure -->
    <v-container fluid>
    
    <header class="jumbotron">
      <h3>Profil de {{ current_user ? current_user.first_name : "[N/A]" }} {{ current_user ? current_user.name : "[N/A]" }}</h3>
    </header>
    <p>
      <strong>Jeton : </strong>
      {{ current_user ? current_user.token.substring(0, 20) : "[N/A]" }} ...
      {{ current_user ? current_user.token.substr(current_user.token.length - 20) : "[N/A]" }}
    </p>
    <p>
      <strong>Numéro : </strong>
      {{ current_user ? current_user.id : "[N/A]" }}
    </p>
    <p>
      <strong>Email/Identifiant :</strong>
      {{ current_user ? current_user.email : "[N/A]" }}
    </p>
    <p>
    <strong>Rôle : </strong>
        {{ current_user ? current_user.role : "[N/A]" }}
    </p>
  
  </v-container>
</template>

<script>
export default {
  name: "profile",
  computed: {
    current_user() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.current_user) {
      this.$router.push("/login");
    }
  }
};
</script>
